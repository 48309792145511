import React from "react";
import { Link } from "gatsby";

import StaticLayout from "../components/StaticLayout";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-667"
        className="post-667 post type-post status-publish format-standard hentry category-geen-categorie"
      >
        <header className="entry-header">
          <h1 className="entry-title">Solodans</h1>
          <div className="entry-meta" />
          {/* .entry-meta */}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            Ben je op zoek naar avontuur, maar ontbreekt het je aan een
            danspartner? Of misschien heb je een partner die liever op de
            zijlijn staat? Geen zorgen, want wij hebben dé oplossing voor jouw
            dansambities: Solodanslessen!
          </p>
          <p>
            Leer toffe choreografieën in salsa, mambo, chacha, bachata, en meer!
            Elke choreografie is speciaal ontworpen om solo uit te voeren.
          </p>
          <p>
            Klaar om jouw dansavontuur te starten? Meld je aan voor onze
            Solodanslessen.
          </p>
          <p>
            <Link className="custom-button" to="/registratie-cursussen">
              Inschrijven voor deze lessenreeks
            </Link>
          </p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1920 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1925,0" />
        </svg>
      </div>
      <nav className="navigation post-navigation" role="navigation">
        <h2 className="screen-reader-text">Berichtnavigatie</h2>
        <div className="nav-links">
          <div className="nav-previous">
            <Link to="/">Terug naar de startpagina</Link>
          </div>
        </div>
      </nav>
    </main>
  </StaticLayout>
);

export default Page;
